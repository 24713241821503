import ctx from "../ctx";
import jwtParser from "../utils/jwtParser";
import storage from "../utils/storage";

export default function rightFilter(right) {
  if (
    !this.$router.history.current.meta ||
    !this.$router.history.current.meta.access
  )
    return;
  if (!ctx.access) ctx.access = jwtParser.parse(storage.getJwt()).access;
  const acc = ctx.access.find(i => {
    return i.access === this.$router.history.current.meta.access;
  });
  if (acc?.rights.includes(right)) return true;
  return false;
}
