const groups = [
  {
    key: "insurance",
    label: "Страхование",
    icon: "all_inclusive",
    expanded: true,
    links: []
  },
  {
    key: "directory",
    label: "Справочники",
    icon: "list",
    expanded: false,
    links: []
  },
  {
    key: "reports",
    label: "Отчеты",
    icon: "insert_chart_outlined",
    expanded: false,
    links: []
  },
  {
    key: "settings",
    label: "Настройки",
    icon: "settings",
    expanded: false,
    links: []
  }
];

export default function navigationFilter(routes) {
  let arr = [];
  groups.forEach(group => {
    group.links = routes.filter(r => r.meta.group === group.key);
  });
  groups.forEach(g => {
    if (g.links.length > 0) arr.push(g);
  });
  return arr;
}
