import ctx from "@/ctx";
import fetch from "../../utils/fetch";
import js2vm from "../../utils/js2vm";
import dateIsoFilter from "../../filters/dateIso.filter";

let Item = function(d, awaiting = true) {
  let self = this;

  self.awaiting = awaiting;

  self.birthDate2vm = function(val) {
    return dateIsoFilter(val);
  };

  self.lastName2vm = function(val) {
    return self.awaiting ? "Загрузка.." : val;
  };

  js2vm(self, d);

  self.setFullName = function() {
    self.fullName = self.awaiting
      ? "Загрузка.."
      : (self.lastName ? " " + self.lastName : "") +
        (self.firstName ? " " + self.firstName : "") +
        (self.middleName ? " " + self.middleName : "");
  };

  self.setFullName();
};

Item.prototype.awaiting = true;

let Items = function() {
  let self = this;

  self.items = [];

  self.drop = function(id) {
    return new Promise(resolve => {
      const items = self.items.filter(i => {
        return i.id === id;
      });
      items.forEach(item => {
        this.items.splice(item, 1);
      });
      resolve();
    });
  };

  self.assign = function(person) {
    return new Promise(resolve => {
      const items = self.items.filter(i => {
        return i.id === person.id;
      });
      items.forEach(item => {
        Object.assign(item, person);
        item.setFullName();
  
      });
      resolve();
    });
  };

  self.get = function(id) {
    if (!id) return;
    let res = self.items.filter(i => {
      return i.id === id;
    });

    if (res.length > 0) return res[0];

    self.items.push(new Item({ id: id }));
    get(id);
    return getAwaiting(id);
  };

  async function get(id) {
    const res = await fetch.get("/api/persons/get?id=" + id);
    let item = getAwaiting(id);
    item.awaiting = false;
    if (res) {
      js2vm(item, res);
      item.setFullName();
    } else item.fullName = "n/a";
  }

  function getAwaiting(id) {
    return self.items.find(i => {
      return i.id === id && i.awaiting;
    });
  }
};

export default async () => {
  console.log("ctx.src.persons: loaded");
  ctx.src.persons = new Items();
  console.log("ctx.src.persons: initialized");
};
