export default {
  getItem: key => {
    return window.localStorage.getItem(key);
  },
  setItem: (key, value) => {
    return window.localStorage.setItem(key, value);
  },
  removeItem: key => {
    return window.localStorage.removeItem(key);
  },
  clear: () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    return location.replace("/");
  },
  getJwt: () => {
    return window.localStorage.getItem("jwt");
  },
  getName: () => {
    return window.localStorage.getItem("name");
  },
  getUserName: () => {
    return window.localStorage.getItem("userName");
  },
  getAvatar: () => {
    return window.localStorage.getItem("avatar");
  },
  getLang: () => {
    return window.localStorage.getItem("lang");
  }
};
