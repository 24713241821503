import ctx from "@/ctx";
import fetch from "../../utils/fetch";


let Items = function(d) {
  let self = this;

  self.items = d;

  self.get = function(id) {
    return self.items.find(i => {
      return i.id === id;
    });
  };

  self.byType = function(typeKey) {
    return self.items.filter(i => {
      return i.typeKey === typeKey;
    });
  };
};

export default async () => {
  console.log("ctx.src.doctors: loaded");
  const res = await fetch.get("/api/src/doctors/get-list");
  ctx.src.doctors = new Items(res);
  console.log("ctx.src.doctors: initialized");
};
