import ctx from "@/ctx";
import fetch from "../../utils/fetch";
import jwtParser from "@/utils/jwtParser";
import storage from "@/utils/storage";

let Items = function() {
  let self = this;
  self.items = [];

  self.get = function(id) {
    return self.items.find(i => {
      return i.id === id;
    });
  };

  self.createdByUser = async function(contractId) {
    const userId = jwtParser.parse(storage.getJwt()).userId;
    return await fetch.get(`/api/insurance/contracts/created-by-user?contractId=${contractId}&userId=${userId}`);
  }

  self.init = async function() {
    self.items = []
    const res = await fetch.get("/api/insurance/contracts/get-all");
    if (res) self.items = res;
  }
};

export default async () => {
  console.log("ctx.src.contracts: loaded");
  ctx.src.contracts = new Items()
  await ctx.src.contracts.init()
  console.log("ctx.src.contracts: initialized");
};
