<template>
  <div class="navbar">
    <v-menu
      v-for="group in groups"
      :key="group.key"
      offset-y
      class="navbar-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon left>
            {{ group.icon }}
          </v-icon>
          {{ group.label }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="link in group.links"
          :key="link.path"
          :to="link.path"
          :exact="link.exact"
          link
        >
          <v-list-item-title>{{ link.meta.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          color="primary"
          fab
          dark
          small
          depressed
          v-on="on"
        >
          <v-icon>account_circle</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ storage.getName() }}</v-list-item-title>
            <v-list-item-subtitle>{{
              storage.getUserName()
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item @click="profile">
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Профиль</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="storage.clear()">
            <v-list-item-icon>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import navigationFilter from "@/filters/navigation.filter";
import storage from "../../utils/storage";

export default {
  data: () => ({
    storage,
    groups: []
  }),
  methods: {
    profile() {
      this.$router.push("/profile");
    }
  },
  mounted() {
    this.groups = navigationFilter(this.$router.options.routes);
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 959px) {
  .navbar-menu {
    display: none;
  }
}
.navbar {
  display: flex;
  flex-grow: 1;
  margin-left: 2rem;
}
.md-button-text {
  margin-left: 0.5rem;
}
</style>
