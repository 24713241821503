import ctx from "@/ctx";
import fetch from "../../utils/fetch";

let Items = function(d) {
  let self = this;
  self.items = d;
  let date = new Date();
  date.setHours(0, 0, 0, 0);
  self.now = date.getTime();

  self.get = function(id) {
    return self.items.find(i => {
      return i.id === id;
    });
  };



  self.byOrganizationId = function(organizationId) {
    return self.list().filter(i => i.organizationId === organizationId);
  };

  self.byCategory = function(categoryId) {
    if (!categoryId) return self.items;
    return self.items.filter(i => {
      return i.categoryId === categoryId && !i.deleted;
    });
  };

  self.list = function() {
    return self.items.filter(i => {
      return !i.deleted && (!i.activeTo || i.activeTo >= self.now);
    });
  };

  self.init = async function() {
    self.items =  await fetch.get("/api/accounting/services/get-services");
  }
  self.init();
};

export default async () => {
  console.log("ctx.src.services: loaded");
  const res = await fetch.get("/api/accounting/services/get-services");
  if (res) ctx.src.services = new Items(res);
  console.log("ctx.src.services: initialized");
};
