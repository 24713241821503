export default {
    state: () => {
        return {
            editable: false
        }
    },
    mutations: { 
        calculateIfOrganizationIsEditable(state, payload) {
            console.log(payload)
            state.editable = payload.isNewEntry || !payload.isKiasOrganization || payload.userCanEditKiasContracts; 
    },
    
},

getters: {
    isOrganizationEditable: state => state.editable
}

}