<template>
<Icon class="custom-icon">

</Icon>
</template>

<script>

import Icon from '@/assets/icons/invoice-service-history-icon.svg'

export default {
  name: "InvoiceServiceLogIcon",
  components: {
    Icon
  }
}
</script>

<style scoped>
  .custom-icon {
    fill: currentColor
  }
</style>
