<template>
  <div class="sidebar">
    <v-toolbar>
      <v-toolbar-title>Навигация</v-toolbar-title>
    </v-toolbar>

    <v-list>
      <v-list-group
        v-for="item in groups"
        :key="item.key"
        v-model="item.active"
        :prepend-icon="item.icon"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.label"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.links"
          :key="child.path"
          :to="child.path"
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.meta.label"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import navigationFilter from "@/filters/navigation.filter";
export default {
  props: ["value"],
  data: () => ({
    expandSingle: true,
    groups: [],
  }),
  mounted() {
    this.groups = navigationFilter(this.$router.options.routes);
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .v-list {
    flex-grow: 1000;
    overflow: auto;
  }
}
</style>
