export default {
    state: () => {
        return {
            editable: false
        }
    },
    mutations: { 
        calculate(state, payload) {
            console.log(payload)
            state.editable = payload.isNewEntry || !payload.isKiasContract || payload.userCanEditKiasContracts; 
    },
    
},

getters: {
    isContractEditable: state => state.editable
}


}