<template>
  <v-app>
    <v-navigation-drawer v-model="menuVisible" app absolute bottom temporary>
      <Sidebar />
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon
        @click="menuVisible = !menuVisible"
      ></v-app-bar-nav-icon>
      <router-link to="/" class="app-title">
        <img src="../assets/logo.png" alt="logo" class="logo md-title" />
        mdb
      </router-link>
      <Navbar />
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Sidebar from "@/components/app/Sidebar";

export default {
  name: "main-layout",
  data: () => ({
    menuVisible: false
  }),
  components: {
    Navbar,
    Sidebar
  }
};
</script>

<style lang="scss" scoped>
.app-main-layout {
  width: 100%;
}

.app-title {
  color: #66c3d0 !important;
  font-weight: 400;
  font-size: 2em;
  display: flex;

  .logo {
    width: 1.6em;
    margin: 8px;
  }
}

#sidebar {
  overflow: hidden !important;
}
</style>
