function reset(vm, js) {
  for (let key in vm) {
    if (vm.__proto__[key] === undefined && js[key] === undefined) {
      if (typeof vm[key] !== 'function' && typeof vm[key + '2vm'] !== 'function') {
        vm[key] = undefined;
      }
    }
  }
  return vm;
}

export default (vm_, js, reset_ = false) => { // reset_ <-- сбрасывает атрибуты модели, если их нет в js-объекте
  return new Promise((resolve) => {
    let vm = reset_ ? reset(vm_, js) : vm_;
    for (let key in js) {
      if (vm[key + '2vm']) vm[key] = vm[key + '2vm'](js[key]);
      else vm[key] = js[key];
    }
    resolve(vm);
  })
};
