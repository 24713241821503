import Vue from 'vue'
import Vuex from 'vuex'
import src from './modules/ctx.src'
import contract from "./modules/contract"
import organization from "@/store/modules/organization";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    src,
    contract,
    organization
  }
})
