import ctx from "@/ctx";
import fetch from "../../utils/fetch";

let Items = function() {
  let self = this;

  self.items = [];

  self.find = function(serviceId, organizationId) {
    return self.items.find(i => {
      return i.serviceId === serviceId && i.organizationId === organizationId;
    });
  };

  self.init = async function() {
    self.items = await fetch.get("/api/accounting/price-list/src");
  };

  self.init();
};

export default async () => {
  console.log("ctx.src.prices: loaded");
  ctx.src.prices = new Items();
  console.log("ctx.src.prices: initialized");
};
