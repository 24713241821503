import ctx from "@/ctx";
import fetch from "../../utils/fetch";
import js2vm from "../../utils/js2vm";
import moment from "moment";
import insuredCardStatusEnum from "@/utils/staticData/insuredCardStatusEnum";
const vmsPattern = '^CVMC.*';
export function getCardStatusByInsuredData(options) {
  if(moment().isBefore(moment(options.startDate))) return insuredCardStatusEnum.NOT_STARTED;
  else if (moment().isAfter(moment(options.endDate))) return insuredCardStatusEnum.EXPIRED;
  else if (options.cardNo.match(vmsPattern)) return insuredCardStatusEnum.VMS
  return insuredCardStatusEnum.RELEVANT;
}

let Item = function(d) {
  let self = this;

  js2vm(self, d);

  self.label = d.cardNo + " - " + d.fullName;
  self.cardStatus = getCardStatusByInsuredData({
    startDate: self.startDate,
    endDate: self.endDate,
    cardNo: self.cardNo
  });
};

let Items = function(d) {
  let self = this;
  self.items = [];

  self.get = function(id) {
    return self.items.find(i => {
      return i.id === id;
    });
  };

  self.pull = async function(id) {
    if (!id) return;
    const res = await fetch.get("/api/src/insured/get?id=" + id);
    if (res) {
      let found = self.items.find(i => {
        return i.id === id;
      });
      if (found) found = new Item(res);
      else this.items.push(new Item(res));
    }
  };

  d.forEach(i => {
    self.items.push(new Item(i));
  });
};



export default async () => {
  console.log("ctx.src.insured: loaded");
  const res = await fetch.get("/api/src/insured/get-list");
  if (res) ctx.src.insured = new Items(res);
  console.log("ctx.src.insured: initialized");
};
