import ctx from "@/ctx";
import fetch from "../../utils/fetch";

let Items = function(d) {
  let self = this;
  self.items = d;

  self.get = function(id) {
    return self.items.find(i => {
      return i.id === id;
    });
  };
};

export default async () => {
  console.log("ctx.src.services_categories: loaded");
  const res = await fetch.get("/api/accounting/services/get-categories");
  if (res) ctx.src.categories = new Items(res);
  console.log("ctx.src.services_categories: initialized");
};
