import storage from "../../utils/storage";
import fetch from "../../utils/fetch";

export default async () => {
  const profile = await fetch.get("/api/auth/profile");
  storage.setItem("id", profile.id);
  storage.setItem("name", profile.name);
  storage.setItem("userName", profile.userName);
  console.log("ctx.profile: setted");
};
