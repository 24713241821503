<template>
  <v-snackbar v-model="show" top>
    {{ ctx.system.msg }}
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        style="margin-right: 8px"
        @click="show = false"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import ctx from "@/ctx";
export default {
  data() {
    return {
      ctx,
      show: false
    };
  },
  watch: {
    show: function(val) {
      if (!val) ctx.system.msg = undefined;
    },
    "ctx.system.msg": function(val) {
      if (val) this.show = true;
    }
  }
};
</script>
