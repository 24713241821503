import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import dateLocalFilter from "./filters/dateLocal.filter";
import dateIsoFilter from "./filters/dateIso.filter";
import rightFilter from "./filters/right.filter";
import datetimeLocalFilter from "./filters/datetimeLocal.filter";

Vue.config.productionTip = false

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

Vue.filter('dateLocalFilter', dateLocalFilter)
Vue.filter('dateIsoFilter', dateIsoFilter)
Vue.filter('rightFilter', rightFilter)
Vue.filter('datetimeLocalFilter', datetimeLocalFilter)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
