import ctx from "@/ctx";
import fetch from "../../utils/fetch";
import js2vm from "../../utils/js2vm";

let Item = function(d) {
  let self = this;

  self.name = undefined;

  self.name2vm = function(val) {
    return !val ? "Загрузка.." : val;
  };

  js2vm(self, d);
};

Item.prototype.awaiting = true;

let Items = function() {
  let self = this;

  self.items = [];
  self.contracts = [];

  self.get = function(id) {
    if (!id) return;
    let res = self.items.filter(i => {
      return i.id === id;
    });

    if (res.length > 0) return res[0];

    self.items.push(new Item({ id: id }));
    get(id);
    return getAwaiting(id);
  };

  self.byContract = function(contractId) {
    if (!contractId) return;
    let loaded = self.contracts.find(c => {
      return c === contractId;
    });
    if (loaded)
      return self.items.filter(i => {
        return i.contractId === loaded;
      });

    byContract(contractId).then(r => {
      merge(r);
    });
    return [];
  };

  async function get(id) {
    const res = await fetch.get("/api/insurance/programs/get?id=" + id);
    let item = getAwaiting(id);
    item.awaiting = false;
    if (res) {
      await js2vm(item, res);
    } else item.name = "n/a";
  }

  function getAwaiting(id) {
    return self.items.find(i => {
      return i.id === id && i.awaiting;
    });
  }

  async function byContract(contractId) {
    const res = await fetch.get(
      "/api/insurance/programs/get-by-contract?contractId=" + contractId
    );
    if (res && res.length > 0) {
      self.contracts.push(contractId);
    }
    return res;
  }

  function merge(arr) {
    if (!arr) return;
    arr.forEach(a => {
      let found = self.items.find(i => {
        return i.id === a.id;
      });
      if (found) {
        found.awaiting = false;
        js2vm(found, a);
      } else {
        let new_ = new Item({});
        new_.awaiting = false;
        js2vm(new_, a);
        self.items.push(new_);
      }
    });
    return self.items.filter(i => {
      return i.contractId === arr[0].contractId;
    });
  }
};

export default async () => {
  console.log("ctx.src.programs: loaded");
  ctx.src.programs = new Items();
  console.log("ctx.src.programs: initialized");
};
