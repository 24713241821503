<template>
  <div id="app">
    <AppLoader v-if="!inited" />
    <SystemMessage/>
    <component :is="layout" style="z-index: 1;">
      <router-view />
    </component>
  </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import AppLoader from "./layouts/AppLoader";
import SystemMessage from "./components/SystemMessage";
import ctx from "./ctx";
import fetch from "./utils/fetch";

export default {
  data: () => ({
    ctx,
    inited: false,
    systemMessage: undefined
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    }
  },
  methods: {
    /**
     * octellIntegrationAuth
    */
     async octellIntegrationAuth(operatorID, cardNo) {
      try {
        const response = await fetch.octell(`/api/oktell/secret-key-auth`, operatorID);
        console.log('response --- >', response);
        ctx.setAuthFromOktell(response, cardNo);
      } catch (error) {
        console.log(error);
      } 
    },
  },
  watch: {
    "ctx.inited": function(val) {
      this.inited = val;
    },
  },
  components: {
    AppLoader,
    EmptyLayout,
    MainLayout,
    SystemMessage
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const operatorID = urlParams.get('operatorID');
    const cardNo = urlParams.get('cardNo');

    console.log('operatorID', operatorID); 
    console.log('cardNo', cardNo);

    if(operatorID && cardNo) {
      setTimeout(() => {
        if(operatorID && !localStorage.getItem('jwt')) {
          this.octellIntegrationAuth(operatorID, cardNo);
        }
        else if(operatorID && localStorage.getItem('jwt') && cardNo) {
          location.replace(location.origin + `/insured?cardNo=${cardNo}`);
        }
      }, 2000);
    }

  }
};
</script>

<style lang="scss">
@import "assets";
</style>
