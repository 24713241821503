import storage from "../utils/storage";
import src from "./src";
import profile from "./ profile";

export default {
  inited: false,
  system: {
    msg: undefined
  },
  logout(url = location.origin) {
    storage.clear();
    location.replace("/login?redirectUrl=" + url);
  },

  async setAuthFromResponse(res) {
    storage.setItem("jwt", res.jwt);
    if (res.pwdNeedChange)
      location.replace(location.origin + "/profile?pwdNeedChange=true");
    else location.replace(location.origin + "/");
  },

  async setAuthFromOktell(res, cardNo) {
    storage.setItem("jwt", res.jwt);
    location.replace(location.origin + `/insured?cardNo=${cardNo}`)
  },

  // async setAuthOktellSecretKey(res, cardNo) {
  //   if (res.pwdNeedChange)
  //     location.replace(location.origin + "/profile?pwdNeedChange=true");
  //   else location.replace(location.origin + `/insured?cardNo=${cardNo}`);
  // },

  async init() {
    if (this.inited) return;
    console.log("ctx: loaded");
    await profile();
    await Promise.all([src()]);
    console.log("ctx: initialized");
    this.inited = true;
    console.log(this);
  }
};
