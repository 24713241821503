import ctx from "@/ctx";
import fetch from "../../utils/fetch";

let Dictionary = function(d) {
  let self = this;

  self.id = d.id
  self.deleted = d.deleted
  self.key = d.key
  self.title = d.title
  self.items = d.items

  self.get = function(key) {
    return self.items.find(i => {
      return i.key === key;
    });
  };
}

let Dictionaries = function() {
  let self = this;
  self.getDictItems = key => {
    return self[key].items || [];
  };
};

export default async () => {
  console.log("ctx.src.dictionaries: loaded");
  const d = await fetch.get("/api/dict/get-list");
  let dict = new Dictionaries();
  for (let i in d) {
    dict[d[i].key] = new Dictionary(d[i]);
  }
  ctx.src.dict = dict;
  console.log("ctx.src.dictionary: initialized");
};
