import ctx from "@/ctx";
import fetch from "../../utils/fetch";

let Items = function() {
  let self = this;
  self.items = [];

  self.get = function(id) {
    return self.items.find(i => {
      return i.id === id;
    });
  };

  self.init = async function() {
    self.items = []
    const res = await fetch.get("/api/users/get-operators");
    if (res) self.items = res;
  }
};

export default async () => {
  console.log("ctx.src.operators: loaded");
  ctx.src.operators = new Items()
  await ctx.src.operators.init()
  console.log("ctx.src.operators: initialized");
};
