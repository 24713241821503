import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import colors from 'vuetify/lib/util/colors'
import ru from 'vuetify/es5/locale/ru'
import InvoiceServiceLogIcon from "@/components/icons/InvoiceServiceLogIcon";

Vue.use(Vuetify)

const opts = {
	theme: {
    themes: {
      light: {
        primary: colors.cyan,
        secondary: '#41465a',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      }
    },
  },
  icons: {
    iconfont: 'md',
    values: {
      invoiceServiceLog: {
        component: InvoiceServiceLogIcon
      }
    }
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
}

export default new Vuetify(opts)

