import Vue from "vue";
import VueRouter from "vue-router";
import storage from "../utils/storage";
import ctx from "../ctx";
import routes from "./routes"

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes.accessible()
});

router.beforeEach((to, from, next) => {
  let jwt = storage.getJwt();
  if (to.matched.some(record => record.meta.auth) && !jwt) {
    next("login");
  } else {
    if (jwt)
      ctx.init().then(() => {
        next();
      });
    else next();
  }
});

const DEFAULT_TITLE = "SOS mdb";
router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.label + " - " + DEFAULT_TITLE || DEFAULT_TITLE;
  });
});

export default router;
