import moment from "moment";

const format = "DD.MM.YYYY HH:mm:ss";

export default function datetimeLocalFilter(date) {
  if (!date) return;
  if (moment(date, format).format(format) === date) return date;
  return date
    ? moment(date)
        .utcOffset("+05:00")
        .format(format)
    : null;
}